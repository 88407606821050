<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="breath-cell">
      <cell-unit :style="{ width: '5%' }" :title="userName"></cell-unit>
      <cell-unit :style="{ width: '18%' }" :title="reportDate"></cell-unit>
      <cell-unit :style="{ width: '14%' }" :title="device"></cell-unit>
      <cell-unit :style="{ width: '6%' }" :title="mode"></cell-unit>
      <div class="breath-cell-mask-unit" :style="{ width: '10%' }">
        <p v-if="maskArray[0]">{{ maskArray[0] }}</p>
        <p v-if="maskArray[1]">{{ maskArray[1] }}</p>
      </div>
      <cell-unit :style="{ width: '5%' }" :title="ahi"></cell-unit>
      <cell-unit :style="{ width: '12%' }" :title="p90"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :color="useTime.isRed ? '#FF0000' : undefined"
        :title="useTime.text"></cell-unit>
      <cell-unit :style="{ width: '7%' }" :color="leak.isRed ? '#FF0000' : undefined" :title="leak.text"></cell-unit>
      <cell-unit :style="{ width: '7%' }" :title="averageBloodOxygen"></cell-unit>
      <cell-unit :style="{ width: '7%' }" :title="minBloodOxygen"></cell-unit>
      <div class="breath-cell-button-container" :style="{ width: '5%' }">
        <div class="breath-cell-button-container-content">
          <cell-unit v-if="canCheckReport" color="#1890FF" :icon="require('@imgs/user-main-check.png')" title="查看"
            cursor="pointer" @onClick="onCheckDetailClick"></cell-unit>
          <p class="breath-cell-button-container-content-invalid" v-else>无效报告</p>
        </div>
      </div>
    </div>
  </list-cell>
</template>
  
<script>
import CellUnit from "@c/common/paging-list/cell-unit.vue";
import ListCell from "@c/common/paging-list/list-cell.vue";
import { DateTool } from "@js/date-tool.js";
import { isEmpty } from '@js/empty-tool';

export default {
  components: {
    ListCell,
    CellUnit,
  },

  props: {
    model: Object,
    isSelected: Boolean,
    maskTypeArray: Array
  },

  computed: {
    userName() {
      return placeholderText(this.model.name);
    },

    reportDate() {
      if (isEmpty(this.model.startTime)) {
        return '-'
      }
      if (this.model.reportType === 3) {
        const createDate = DateTool.milliTimestampToStr(this.model.startTime, "YYYY-MM-DD");
        const startTime = placeholderText(DateTool.milliTimestampToStr(this.model.startTime, "HH:mm"));
        const endTime = placeholderText(DateTool.milliTimestampToStr(this.model.endTime, "HH:mm"));
        return `${createDate} （${startTime}-${endTime}）`;
      } else {
        const rawSingleReportCreateDate = new Date(this.model.startTime)
        const 小于正午12点 = rawSingleReportCreateDate < this.getAM12Time(rawSingleReportCreateDate)
        const startTimestamp = 小于正午12点 ? this.model.startTime - 24 * 3600 * 1000 : this.model.startTime;
        const endTimestamp = 小于正午12点 ? this.model.endTime - 24 * 3600 * 1000 : this.model.endTime;
        const createDate = DateTool.milliTimestampToStr(startTimestamp, "YYYY-MM-DD");
        const startTime = placeholderText(DateTool.milliTimestampToStr(startTimestamp, "HH:mm"));
        const endTime = placeholderText(DateTool.milliTimestampToStr(endTimestamp, "HH:mm"));
        return `${createDate} （${startTime}-${endTime}）`;
      }

    },

    device() {
      const sn = placeholderText(this.model.sn);
      return `${sn}`
    },

    mode() {
      return placeholderText(this.model.cureMode);
    },

    maskArray() {
      if (isEmpty(this.model.maskTypeId)) {
        return ['-']
      }
      console.log(this.model.maskTypeId)
      const array = this.maskTypeArray.find(el => el.value === this.model.maskTypeId)?.title?.split(' ')
      if (array.length) {
        return array
      } else {
        return ['-']
      }
    },

    ahi() {
      if (this.model.reportType === 1) {
        return placeholderText(this.model.ahi);
      } else if (this.model.reportType === 2) {
        if (this.model.validity != 1) {
          return '-'
        } else {
          return placeholderText(this.model.downIndex);
        }
      } else {
        if (this.model.complete != window.SLEEP_REPORT_COMPLETENESS.完整) {
          return '-'
        } else {
          return placeholderText(this.model.ahi);
        }
      }

    },

    p90() {
      return placeholderText(this.model.tpIn);
    },

    useTime() {
      const duration = this.model.longTime;
      const text = placeholderText(DateTool.formatSeconds(duration));
      const isRed = !isNaN(duration) && duration < 60 * 60 * 4;
      return {
        text,
        isRed,
      };
    },

    leak() {
      const text = placeholderText(this.model.leakage, "L/min");
      const leakNumber = parseFloat(this.model.leakage);
      const isRed = !isNaN(leakNumber) && leakNumber > 5;
      return {
        text,
        isRed,
      };
    },

    averageBloodOxygen() {
      if (this.model.reportType === 1) {
        const averageBloodOxygen = parseFloat(this.model.spoAvg); //为了使0显示为/
        return placeholderText(averageBloodOxygen, "%");
      } else if (this.model.reportType === 2) {
        if (this.model.validity != 1) {
          return '-'
        }
        const averageBloodOxygen = parseFloat(this.model.spoAvg); //为了使0显示为/
        return placeholderText(averageBloodOxygen, "%");
      } else {
        if (this.model.complete != window.SLEEP_REPORT_COMPLETENESS.完整) {
          return '-'
        }
        const averageBloodOxygen = parseFloat(this.model.spoAvg); //为了使0显示为/
        return placeholderText(averageBloodOxygen, "%");
      }
    },

    minBloodOxygen() {
      if (this.model.reportType === 1) {
        const minBloodOxygen = parseFloat(this.model.spoMin); //为了使0显示为/
        return placeholderText(minBloodOxygen, "%");
      } else if (this.model.reportType === 2) {
        if (this.model.validity != 1) {
          return '-'
        }
        const minBloodOxygen = parseFloat(this.model.spoMin); //为了使0显示为/
        return placeholderText(minBloodOxygen, "%");
      } else {
        if (this.model.complete != window.SLEEP_REPORT_COMPLETENESS.完整) {
          return '-'
        }
        const minBloodOxygen = parseFloat(this.model.spoMin); //为了使0显示为/
        return placeholderText(minBloodOxygen, "%");
      }
    },

    canCheckReport() {
      if (this.model.reportType === 3) {
        return this.model.complete == window.SLEEP_REPORT_COMPLETENESS.完整
      } else {
        return true
      }
    },
  },

  methods: {
    onSelectClick() {
      this.$emit("onSelectClick", this.model);
    },

    onCheckDetailClick() {
      this.$emit("onCheckDetailClick", this.model);
    },
    getAM12Time(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return new Date(year, month, day, 12, 0, 0);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.breath-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &-mask-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
  }

  &-button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      display: flex;

      &-right-button {
        margin-left: 21px;
      }

      &-invalid {
        color: #b3b3b3;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}
</style>