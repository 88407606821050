<template>
  <panel-alert title="报告列表" :isShowBottom="false" width="1520px" @onCloseClick="onCloseClick"
    @onConfirmClick="onConfirmClick">
    <!-- <template v-slot:sub-title>
      <p class="sleep-list-alert-sub-title">
        {{ reportDuration }}
      </p>
    </template> -->
    <template v-slot:content>
      <div class="sleep-list-alert">
        <page-list-container height="500px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
          :pageSize="pageSize" @pagination="onPagination">
          <div>
            <list-title-bar></list-title-bar>
            <cell v-for="(item, index) of dataArray" :key="index" :model="item" :maskTypeArray="maskTypeArray"
              :isSelected="selectedID === item.id" @onSelectClick="onCellSelectClick"
              @onCheckDetailClick="onCheckDetailClick"></cell>
          </div>
        </page-list-container>
      </div>
    </template>
  </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import PageListContainer from "@c/common/paging-list/paging-list.vue";
import ListTitleBar from "@c/main/patient/all-report-list/all-report-title-bar.vue";
import Cell from "@c/main/patient/all-report-list/all-report-cell.vue";
import { DateTool } from "@js/date-tool.js";
import { maskTypeArray } from "@js/parameter-option-data-source.js";
import { isEmpty } from "@js/empty-tool.js";

export default {
  components: {
    PanelAlert,
    PageListContainer,
    ListTitleBar,
    Cell,
  },

  props: {
    uid: Number,
    hospitalId: Number,
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 0,
    },
  },

  data: function () {
    return {
      //标题
      // reportDuration: undefined,
      //列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 6,
      //选中
      selectedID: undefined,
      maskTypeArray: []
    };
  },

  created() {
    this.getMaskType()
    this.getReportList();
  },

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.$emit("onConfirmClick");
    },

    onPagination: function (currentPage) {
      this.currentPage = currentPage;
      this.getReportList();
    },

    onCellSelectClick(model) {
      this.selectedID = model.id;
    },

    onCheckDetailClick(model) {
      if (model.reportType === 1) {
        //跳转报告详情
        let routeData = this.$router.resolve({
          path: "/report-frame",
          query: {
            uid: this.uid,
            singleReportID: model.id,
            hospitalId: this.hospitalId
          },
        });
        window.open(routeData.href, "_blank");
      } else if (model.reportType === 2) {
        let routeData = this.$router.resolve({
          path: "/report-frame",
          query: {
            uid: this.uid,
            reportType: 2,
            singleBloodOxygenReportId: model.id,
            hospitalId: this.hospitalId
          },
        });
        window.open(routeData.href, "_blank");
      } else if (model.reportType === 3) {
        const pdfUrl = model.pdfUrl;
        if (pdfUrl) {
          let routeData = this.$router.resolve({
            path: "/pdf-report",
            query: {
              pdfUrl
            },
          });
          window.open(routeData.href, "_blank");
        } else {
          this.$toast.showRed("报告地址为空")
        }
      }


    },

    // method
    async getReportList() {
      try {
        const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          uid: this.uid,
          // start: this.start,
          // end: this.end,
          hospitalId: this.hospitalId,
        };
        const data = await this.$api.getAllReportList(params);
        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async getMaskType() {
      try {
        this.maskTypeArray = await maskTypeArray()
      } catch (error) {

      }

    }
  },
};
</script>
  
<style lang="scss" scoped>
.sleep-list-alert {
  position: relative;
  display: flex;
  flex-direction: column;

  &-sub-title {
    color: black;
    font-size: 14px;
    margin-top: 23px;
    margin-left: 15px;
  }

  &-top {
    display: flex;
    flex-direction: column;

    &-row {
      position: relative;
      margin-top: 50px;
      width: 100%;
      display: flex;
    }

    &-unit {
      position: absolute;

      &-slot {
        margin-left: 40px;
        font-weight: 300;
      }

      &-right {
        left: 230px;
      }
    }
  }

  &-bottom {
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    &-cell {
      position: relative;
      font-size: 14px;
      color: black;
      font-weight: 300;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-name {
        position: absolute;
        font-weight: 400;
      }

      &-time {
        position: absolute;
        left: 60px;
      }

      &-ahi {
        position: absolute;
        left: 230px;
      }

      &-check {
        position: absolute;
        color: #2e6be6;
        left: 380px;
      }
    }
  }
}
</style>
