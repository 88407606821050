<style lang="scss" scoped>
.list-title-bar-sleep {
  height: 54px;
  background-color: #fafafa;
  justify-content: space-around;
}
</style>

<template>
  <list-title-bar :titleArray="titleArray"></list-title-bar>
</template>

<script>
import ListTitleBar from "@c/common/paging-list/list-title-bar.vue";

export default {
  components: { ListTitleBar },

  computed: {
    titleArray() {
      return [
        { name: "姓名", percent: 5 },
        { name: "记录日期", percent: 18 },
        { name: "设备", percent: 14 },
        { name: "工作模式", percent: 6 },
        { name: "面罩类型", percent: 10 },
        { name: "AHI/ODI", percent: 5 },
        { name: "P90压力", percent: 12 },
        { name: "使用时长", percent: 8 },
        { name: "平均漏气", percent: 7 },
        { name: "平均血氧", percent: 7 },
        { name: "最低血氧", percent: 7 },
        { name: "操作", percent: 5 },
      ];
    },
  },
};
</script>