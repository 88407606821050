<template>
  <div class="list-title-bar">
    <div
      class="list-title-bar-unit"
      v-for="(item, index) of titleArray"
      :key="index"
      :style="{ width: `${item.percent}%` }"
    >
      {{ item.name }}
    </div>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  props: {
    titleArray: Array,
  },
};
</script>

<style lang="scss" scoped>

.list-title-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  height: 54px;
  position: relative;
  display: flex;

  &-unit {
    color: black;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>