<template>
  <div class="cell-unit" @click="onClick">
    <img v-if="icon" class="cell-unit-icon" :src="icon" />
    <p class="cell-unit-title" :style="{ color: color, cursor: cursor }">
      {{ title }} <slot name="slot"></slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },

    title: String,

    color: {
      type: String,
      default: "#000000",
    },

    cursor: {
      type: String,
      default: "text",
    },
  },

  methods: {
    onClick: function () {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>

.cell-unit {
  display: flex;
  align-items: center;
  justify-content: center;

  &-icon {
    width: 16px;
    margin-right: 3px;
  }

  &-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    //超过省略号
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //slot
    display: flex;
  }
}
</style>
