//模式选项数组
export const modeArray = [{ title: "CPAP", value: 0 }, { title: "APAP", value: 1 }, { title: "BPAP-S", value: 2 }, { title: "AutoBPAP-S", value: 3 }, { title: "BPAP-T", value: 4 }, { title: "BPAP-ST", value: 5 }]
//压力（起始、最大、最小、治疗）选项数组
export function pressureArray(firstItem, lastItem) {
    let array = []
    for (let index = firstItem; index <= lastItem; index += 0.5) {
        const item = {
            title: `${index.toFixed(1)}cmH2O`,
            value: index
        }
        array.push(item)
    }
    return array
}
//呼气释压选项数组
export const breathPressureArray = [{ title: "关闭", value: 0 }, { title: "延迟时", value: 1 }, { title: "全时", value: 2 }]
//释压水平选项数组
export const pressureLevelArray = [{ title: "1", value: 0 }, { title: "2", value: 1 }, { title: "3", value: 2 }, { title: "4", value: 3 }]
//升压速度/降压速度/吸气灵敏度/呼气灵敏度
export const commonArray = [{ title: "1", value: 1 }, { title: "2", value: 2 }, { title: "3", value: 3 }, { title: "4", value: 4 }]
//呼吸频率
export function breathFrequencyArray() {
    let array = []
    for (let index = 5; index <= 40; index++) {
        const item = {
            title: `${index}`,
            value: index
        }
        array.push(item)
    }
    return array
}
//吸呼比选项数组
export function breathRatioArray() {
    let array = []
    for (let index = 0; index <= 10; index++) {
        const ratio = 1 + 0.5 * index
        const item = {
            title: `1:${ratio}`,
            value: index
        }
        array.push(item)
    }
    return array
}
//最小吸气时间选项数组
export function minBreathInTimeArray(breathFrequency, breathRatioIndex) {
    //max
    //最大值=60/呼吸频率*(吸呼比-吸/(吸呼比-吸+吸呼比-呼))
    let breathIn = 1;
    let breathOut = 1 + 0.5 * breathRatioIndex;
    let rawMax = (
        (60 / breathFrequency) *
        (breathIn / (breathIn + breathOut))
    ).toFixed(1);
    //最大值不超过4.0
    let max = Math.min(rawMax, 4);
    //array
    let array = []
    var item = 0
    var index = 0
    while (item < max) {
        item += 0.1
        index++
        const itemModel = {
            title: `${item.toFixed(1)}s`,
            value: index
        }
        array.push(itemModel)
    }
    return array
}
//延迟升压选项数组
export function delayPressureArray() {
    let array = []
    for (let index = 0; index <= 40; index++) {
        if (index % 5 == 0) {
            const item = {
                title: index == 0 ? '关闭' : `${index}min`,
                value: index
            }
            array.push(item)
        }
    }
    return array
}
//湿化等级选项数组
export const wetLevelArray = [{ title: "关闭", value: 0 }, { title: "1", value: 1 }, { title: "2", value: 2 }, { title: "3", value: 3 }, { title: "4", value: 4 }, { title: "5", value: 5 }]
//漏气提示时间选项数组
export const leakRemindTimeArray = [{ title: "关闭", value: 0 }, { title: "15秒", value: 1 }, { title: "30秒", value: 2 }]
//面罩类型选项数组
import Vue from 'vue'
export async function maskTypeArray() {
    try {
        const rawArray = await Vue.prototype.$api.getMaskTypeList()
        const maskTypeArray = rawArray.map((item) => {
            return {
                title: item.maskTypeName,
                value: item.id,
                maskCategory: item.mask //面罩大类
            }
        })
        return maskTypeArray
    } catch (error) {
        return []
    }
}
//管道类型选项数组
export const tubeTypeArray = [{ title: "普通管路", value: 0 }, { title: "加温管路", value: 1 }]

//加温管温度选项数组
export function heatTubeTemperatureArray() {
    let array = []
    for (let index = 16; index <= 30; index++) {
        const item = {
            title: `${index}­°C`,
            value: index
        }
        array.push(item)
    }
    return array
}
//加温管温度类型选项数组 
export const temperatureTypeArray = [{ title: "22mm", value: 0 }, { title: "15mm", value: 1 }]
//温度单位类型选项数组
export const temperatureUnitTypeArray = [{ title: "°C", value: 0 }, { title: "°F", value: 1 }]

//智能开启
export const smartStartArray = [{ title: "关闭", value: 0 }, { title: "开启", value: 1 }]
//智能关闭
export const smartStopArray = [{ title: "关闭", value: 0 }, { title: "开启", value: 1 }]
